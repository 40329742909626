<template>
  <UModal
    v-model:open="isOpen"
    :ui="{
      overlay: 'bg-neutral-950/75 backdrop-blur-sm',
      content:
        'bg-neutral-900/40 backdrop-blur-lg overflow-hidden overflow-y-auto',
      header: 'p-0 sm:p-0 w-full',
      footer: 'p-2 sm:p-2',
    }"
  >
    <UButton
      title="Search"
      size="xl"
      variant="ghost"
      icon="i-lucide-search"
      class="cursor-pointer"
    />

    <template #header>
      <UCommandPalette
        v-model:search-term="searchTerm"
        v-model:selected-value="selectedItem"
        :loading="isSearching"
        :groups="allGroups"
        placeholder="Search users..."
        class="h-64 flex-1"
        :fuse="{
          fuseOptions: {
            threshold: 0.2,
            keys: ['label', 'description'],
          },
          resultLimit: 10,
        }"
        :ui="{
          itemLeadingIcon:
            'text-yellow-400 group-data-highlighted:text-yellow-500',
          itemLabelBase:
            'font-semibold group-data-highlighted:text-yellow-400 ',
        }"
        @update:model-value="onSelect"
        @update:search-term="handleSearchTermUpdate"
      />
    </template>
    <template #footer>
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center space-x-2">
          <UIcon name="i-simple-icons-algolia" class="size-4 text-[#003DFF]" />
          <span class="mr-2 font-mono text-xs text-neutral-400 uppercase">
            Powered By Algolia
          </span>
        </div>
        <div class="hidden items-center space-x-0.5 md:flex">
          <span class="mr-2 font-mono text-xs text-neutral-400 uppercase">
            Search
          </span>
          <UKbd value="meta" /><UKbd value="K" />
        </div>
      </div>
    </template>
  </UModal>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';

const router = useRouter();
const searchTerm = ref('');
const selectedItem = ref(null);
const searchResults = ref({ hits: [] });
const status = ref('idle');
const isOpen = ref(false);

// Debounced search function for Algolia
const performSearch = async (term: string) => {
  if (!term?.trim()) {
    searchResults.value = { hits: [] };
    return;
  }

  status.value = 'pending';
  try {
    const result = await useAsyncAlgoliaSearch({
      indexName: 'Live For Today',
      query: term,
    });

    const iconMapping = {
      'activities/': 'i-lucide-zap',
      'locations/': 'i-lucide-map-pin',
      'groups/': 'i-lucide-users',
      'events/': 'i-lucide-calendar-days',
      'offers/': 'i-lucide-badge-percent',
      'gifts/': 'i-lucide-gift',
      'blog/': 'i-lucide-play',
      'packages/': 'i-lucide-ticket-percent',
      'press/': 'i-lucide-megaphone',
      contact: 'i-lucide-phone',
      'legal/': 'i-lucide-square-stack',
    };

    if (result.data.value) {
      // Define priority order for sorting
      const priorityOrder = {
        'locations/': 1,
        'activities/': 2,
        'packages/': 3,
        'events/': 4,
      };

      const hits = result.data.value.hits
        .map((hit) => {
          const icon =
            Object.entries(iconMapping).find(([key]) =>
              hit.full_slug?.includes(key)
            )?.[1] || 'i-lucide-file';

          // Determine priority based on full_slug
          const priority =
            Object.entries(priorityOrder).find(([key]) =>
              hit.full_slug?.includes(key)
            )?.[1] || 999; // Default priority for other items

          return {
            label: hit.name,
            icon,
            value: hit.id || hit.objectID,
            to: `/${hit.full_slug}`,
            priority, // Add priority for sorting
          };
        })
        .sort((a, b) => a.priority - b.priority); // Sort by priority

      searchResults.value = {
        hits: hits.map(({ priority, ...item }) => item), // Remove priority from final results
      };
    }
  } catch (error) {
    console.error('Search error:', error);
    searchResults.value = { hits: [] };
  } finally {
    status.value = 'complete';
  }
};

const debouncedSearch = useDebounceFn(performSearch, 300);

const isSearching = computed(() => status.value === 'pending');

// Static menu items
const menuItems = [
  {
    label: 'Locations',
    icon: 'i-lucide-map-pin',
    to: '/locations',
  },
  {
    label: 'Activities',
    icon: 'i-lucide-zap',
    to: '/activities',
  },
  {
    label: 'Packages',
    icon: 'i-lucide-ticket-percent',
    to: '/activities?tab=packages',
  },
  {
    label: 'Events',
    icon: 'i-lucide-calendar-days',
    to: '/events',
  },
];

// Combine static menu items and search results
const allGroups = computed(() => {
  const groups = [
    {
      id: 'menu',
      label: 'Quick Links',
      items: menuItems,
      filter: true,
    },
  ];

  if (searchResults.value?.hits?.length > 0) {
    groups.push({
      id: 'search-results',
      label: 'Search Results',
      items: searchResults.value.hits,
      filter: false,
    });
  }

  return groups;
});

// Handle search term updates
const handleSearchTermUpdate = (term: string) => {
  if (!term?.trim()) {
    searchResults.value = { hits: [] };
    status.value = 'idle';
    return;
  }
  debouncedSearch(term);
};

// Selection handler with navigation and modal closing
const onSelect = async (item) => {
  if (!item) return;

  if (item.to) {
    // Close modal before navigation
    isOpen.value = false;
    // Wait for modal to close before navigating
    await nextTick();
    router.push(item.to);
  }
};

// Keyboard Shortcuts
defineShortcuts({
  meta_k: () => {
    isOpen.value = !isOpen.value;
  },
});
</script>
